/* eslint-disable no-undef */
import React from "react"
import TemplatePageVirtualPBX from "@components/pageFunctionalities/pageVirtualPBX/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Virtual PBX: Centralita telefónica con IA para tu empresa [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/virtual-pbx/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Utiliza el Virtual PBX con IA de Beex para asegurar la continuidad operaciones de tus llamadas entrantes y salientes desde cualquier lugar y con cualquier dispositivo."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Virtual PBX: Centralita telefónica con IA para tu empresa"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/virtual-pbx/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Utiliza el Virtual PBX con IA de Beex para asegurar la continuidad operaciones de tus llamadas entrantes y salientes desde cualquier lugar y con cualquier dispositivo."
      />
    </Helmet>
    <TemplatePageVirtualPBX location={location}/>
  </div>
)

export default IndexPage
